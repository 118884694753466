import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from './models/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private endpoint: string = 'product';
  data$: BehaviorSubject<Product[]>;
  data: Product[];

  constructor(
    private api: ApiService
  ) {
    this.data$ = new BehaviorSubject<Product[]>(null);
  }

  get() {
    return this.api.get(this.endpoint)
      .pipe(map((res: Product[]) => {
        console.log('products retrieved', res.length);
        this.data = res;
        this.data$.next(this.data);
        return res;
      }));
  }

  getById(id: number) {
    return this.api.get(`${this.endpoint}/${id}`)
      .pipe(map((res: Product) => {
        console.log('product retrieved', res);
        return res;
      }));
  }

  getByLocation(locationID: number) {
    return this.api.get(`${this.endpoint}/GetByLocation/${locationID}`)
      .pipe(res => {
        return res;
      });

  }

  saveProduct(product: Product) {
    let url = `${this.endpoint}`;
    if (product.ID) {
      url = `${url}/${product.ID}`;
      return this.api.put(url, product);
    } else {
      return this.api.post(url, product);
    }
  }
}
